import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IDeterministicRunePrice } from '../../api/interfaces'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getDeterministicRuneConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_deterministic_rune_price', isRefreshing, timeframe)) as IDeterministicRunePrice[]
	const series1 = chartData.map((element: IDeterministicRunePrice) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.RUNE_PRICE }
	})
	const series2 = chartData.map((element: IDeterministicRunePrice) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.DETERMINISTIC_RUNE_PRICE }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'rune price',
			type: 'line',
			seriesGroup: '[usd]',
			color: 'green',
			unitSymbol: '$'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'deterministic rune price',
			type: 'line',
			seriesGroup: '[usd]',
			color: 'orange',
			unitSymbol: '$'
		}
	]

	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}

const DeterministicRune = () => {
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getDeterministicRuneConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Deterministic Rune Price"
				chartDesc="The fundamental or 'fair' value of RUNE as a crypto could be seen as 3x the value of non-native assets in the pools (non-native referring to assets that aren't RUNE). Many refer to this as 'deterministic value'."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="88fdb846-eb56-4576-bcf0-af576c4443e0"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default DeterministicRune
