import React, { useEffect, useContext, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ThemeContext } from '../../App'
import { ISwapFeesBySwapType } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const SwapFeesBySwapType = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_swap_fees_by_swap_type', true, timeframe)) as ISwapFeesBySwapType[]

			const series1 = chartData.map((element: ISwapFeesBySwapType) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.STANDARD_FEES }
			})
			const series2 = chartData.map((element: ISwapFeesBySwapType) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.STREAMING_FEES }
			})
			const series3 = chartData.map((element: ISwapFeesBySwapType) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.TOTAL_FEES }
			})
			const series4 = chartData.map((element: ISwapFeesBySwapType) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.TOTAL_FEES_CUMULATIVE }
			})
			setDataSeries([
				{
					data: series4,
					strokeWidth: 2,
					name: 'Cumulative',
					type: 'line',
					seriesGroup: ' [usd]',
					color: `${color}`,
					unitSymbol: '$'
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'Total',
					type: 'line',
					seriesGroup: '[usd]',
					color: `${inverseColor}`,
					unitSymbol: '$'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'Fees from Streaming Swaps',
					type: 'bars',
					seriesGroup: '[usd]',
					color: 'orange',
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series1,
					strokeWidth: 2,
					name: 'Fees from Normal Swaps',
					type: 'bars',
					seriesGroup: '[usd]',
					color: 'green',
					unitSymbol: '$',
					barGroup: 'usd'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseMainColor = theme === 'dark' ? 'black' : 'white'

		InitializeData(mainColor, inverseMainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Swap Fees By Swap Type"
				chartDesc="Swap fees paid by swappers on THORChain by swap type."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="6b2f83ad-7354-4eb5-90fc-f279825c196b"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SwapFeesBySwapType
