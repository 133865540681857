import React, { SetStateAction, useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ILPUnrealizedPL } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import { colors } from '../../const'
import ChartPage from '../../pages/ChartPage/ChartPage'

const AvgLPAge = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_lp_unrealized_pl', true, timeframe)) as ILPUnrealizedPL[]
			const pools = ['BTC.BTC', 'ETH.ETH', 'BSC.BNB', 'LTC.LTC', 'BCH.BCH', 'GAIA.ATOM', 'AVAX.AVAX', 'DOGE.DOGE', 'Stablecoin', 'Others']
			const assetData: ILPUnrealizedPL[][] = []
			const seriesData: { x: number; y: number }[][] = []
			const setSeries: SetStateAction<IChart[] | undefined> = []
			pools.forEach((pool) => {
				assetData.push(chartData.filter((row) => row.ASSET_NAME === pool))
			})
			assetData.forEach((asset) => {
				seriesData.push(
					asset.map((element: ILPUnrealizedPL) => {
						return { x: new Date(element.DAY).getTime() / 1000, y: element.AVG_LP_POOL_AGE }
					})
				)
			})
			let i = 0
			seriesData.forEach((series) => {
				setSeries.push({
					data: series,
					strokeWidth: 2,
					name: pools[i].split('.')[0],
					type: 'line',
					seriesGroup: '[day count]',
					color: colors[i],
					unitSymbol: ''
				})
				i++
			})

			setDataSeries(setSeries)
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Average LP Age by Pool"
				chartDesc="Average time spent by liquidity providers in each of the pools. Adding liquidity resets the age of a liqudity provider."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="9271cce8-fb66-42f5-86c2-85023fbae118"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default AvgLPAge
