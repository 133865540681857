import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ITCProtocolOwnedLiquidity } from '../../api/interfaces'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'
import { colors } from '../../const'

export async function getProtocolOwnedLiquidityConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_pol', isRefreshing, timeframe)) as ITCProtocolOwnedLiquidity[]
	const btcData = chartData.filter((row) => row.ASSET_NAME === 'BTC.BTC')
	const stablecoinData = chartData.filter((row) => row.ASSET_NAME === 'Stablecoin')
	const othersData = chartData.filter((row) => row.ASSET_NAME === 'Others')

	const series1 = btcData.map((element: ITCProtocolOwnedLiquidity) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.RUNE_DEPOSITED }
	})
	const series2 = stablecoinData.map((element: ITCProtocolOwnedLiquidity) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.RUNE_DEPOSITED }
	})
	const series3 = othersData.map((element: ITCProtocolOwnedLiquidity) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.RUNE_DEPOSITED }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'BTC.BTC',
			type: 'line',
			seriesGroup: '[rune]',
			color: `${colors[0]}`,
			unitSymbol: 'ᚱ'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'Stablecoin',
			type: 'line',
			seriesGroup: '[rune]',
			color: `${colors[1]}`,
			unitSymbol: 'ᚱ'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'Others',
			type: 'line',
			seriesGroup: '[rune]',
			color: `${colors[2]}`,
			unitSymbol: 'ᚱ'
		}
	]

	return { config: config, latestValue: 0, latestValueDate: 0 }
}

const ProtocolOwnedLiquidity = () => {
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getProtocolOwnedLiquidityConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Protocol Owned Liquidity"
				chartDesc="The quantity of RUNE allocated to the liquidity pools by the protocol. POL has been introduced to deal with a high demand for minting synths while maintaining a safe synth minting limit by using the RUNE within the Reserve. POL address: thor1dheycdevq39qlkxs2a6wuuzyn4aqxhve4qxtxt."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="58afd6f7-abb4-4af2-b9e5-d3185c31f150"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default ProtocolOwnedLiquidity
