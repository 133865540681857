import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IUniqueSwapperCount } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const UniqueSwapCount = () => {
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_unique_swapper_count', true, timeframe)) as IUniqueSwapperCount[]
			let series1: {
				x: number
				y: number
			}[] = []

			if (timeframe === '1d') {
				series1 = chartData.map((element: IUniqueSwapperCount) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.DAILY_UNIQUE_SWAPPER_COUNT }
				})
			} else if (timeframe === '1w') {
				series1 = chartData.map((element: IUniqueSwapperCount) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_UNIQUE_SWAPPER_COUNT }
				})
			} else if (timeframe === '1m') {
				series1 = chartData.map((element: IUniqueSwapperCount) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_UNIQUE_SWAPPER_COUNT }
				})
			}
			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'unique swap count',
					type: 'bars',
					seriesGroup: '[swapper #]',
					color: 'green'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Unique Swapper Count"
				chartDesc="The number of unique addresses which performed a swap on THORChain."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="9f40c912-a4f3-4377-9419-0bd1d62b8ccd"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default UniqueSwapCount
