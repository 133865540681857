import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ISwapDistribution } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import { colors } from '../../const'
import ChartPage from '../../pages/ChartPage/ChartPage'

const SwapDistribution = () => {
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_swap_distribution', true, timeframe)) as ISwapDistribution[]
			const series1 = chartData.map((element: ISwapDistribution) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.LESS_THAN_100_COUNT }
			})
			const series2 = chartData.map((element: ISwapDistribution) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.LESS_THAN_1K_MORE_THAN_100_COUNT }
			})
			const series3 = chartData.map((element: ISwapDistribution) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.LESS_THAN_5K_MORE_THAN_1K_COUNT }
			})
			const series4 = chartData.map((element: ISwapDistribution) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.LESS_THAN_10K_MORE_THAN_5K_COUNT }
			})
			const series5 = chartData.map((element: ISwapDistribution) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.LESS_THAN_50K_MORE_THAN_10K_COUNT }
			})
			const series6 = chartData.map((element: ISwapDistribution) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.LESS_THAN_100K_MORE_THAN_50K_COUNT }
			})
			const series7 = chartData.map((element: ISwapDistribution) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.MORE_THAN_100K_COUNT }
			})

			setDataSeries([
				{
					data: series7,
					strokeWidth: 2,
					name: 'swap count (100k$, inf)',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[8]}`,
					barGroup: 'stacked'
				},
				{
					data: series6,
					strokeWidth: 2,
					name: 'swap count (50k$, 100k$⟩',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[6]}`,
					barGroup: 'stacked'
				},
				{
					data: series5,
					strokeWidth: 2,
					name: 'swap count (10k$, 50k$⟩',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[5]}`,
					barGroup: 'stacked'
				},
				{
					data: series4,
					strokeWidth: 2,
					name: 'swap count (5k$, 10k$⟩',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[4]}`,
					barGroup: 'stacked'
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'swap count (1k$, 5k$⟩',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[3]}`,
					barGroup: 'stacked'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'swap count (100$, 1k$⟩',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[11]}`,
					barGroup: 'stacked'
				},
				{
					data: series1,
					strokeWidth: 2,
					name: 'swap count (0 ,100$⟩',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[1]}`,
					barGroup: 'stacked'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Swap Count Distribution"
				chartDesc="Swap count on THORChain within the defined brackets."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="2bc3052e-8484-495f-b2eb-b73c7a0d96f6"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				reverseLegend={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SwapDistribution
