import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ISaversAPR } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getSaverAPRConfig(color: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_saver_apr', isRefreshing, timeframe)) as ISaversAPR[]

	const btcData = chartData.filter((row) => row.POOL_NAME === 'BTC/BTC')
	const ethData = chartData.filter((row) => row.POOL_NAME === 'ETH/ETH')
	const bchData = chartData.filter((row) => row.POOL_NAME === 'BCH/BCH')
	const avaxData = chartData.filter((row) => row.POOL_NAME === 'AVAX/AVAX')
	const bnbData = chartData.filter((row) => row.POOL_NAME === 'BNB/BNB')
	const dogeData = chartData.filter((row) => row.POOL_NAME === 'DOGE/DOGE')
	const ltcData = chartData.filter((row) => row.POOL_NAME === 'LTC/LTC')
	const atomData = chartData.filter((row) => row.POOL_NAME === 'GAIA/ATOM')
	const stablecoinData = chartData.filter((row) => row.POOL_NAME === 'Stablecoin')

	const series1 = btcData.map((element: ISaversAPR) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_INCREASE_365D }
	})
	const series2 = ethData.map((element: ISaversAPR) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_INCREASE_365D }
	})
	const series3 = bchData.map((element: ISaversAPR) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_INCREASE_365D }
	})
	const series4 = avaxData.map((element: ISaversAPR) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_INCREASE_365D }
	})
	const series5 = bnbData.map((element: ISaversAPR) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_INCREASE_365D }
	})
	const series6 = dogeData.map((element: ISaversAPR) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_INCREASE_365D }
	})
	const series7 = ltcData.map((element: ISaversAPR) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_INCREASE_365D }
	})
	const series8 = atomData.map((element: ISaversAPR) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_INCREASE_365D }
	})
	const series10 = stablecoinData.map((element: ISaversAPR) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_INCREASE_365D }
	})
	const series9 = []
	for (let i = 0; i < series1.length; i++) {
		let point = {
			x: series1[i].x,
			y: (series1[i].y + series2[i].y + series3[i].y + series4[i].y + series5[i].y + series6[i].y + series7[i].y + series8[i].y + series10[i].y) / 9
		}
		series9.push(point)
	}

	const config: IChart[] = [
		{
			data: series9,
			strokeWidth: 2,
			name: 'AVERAGE',
			type: 'line',
			seriesGroup: '[%]',
			color: '#e505f5',
			unitSymbol: '%'
		},
		{
			data: series1,
			strokeWidth: 2,
			name: 'BTC',
			type: 'line',
			seriesGroup: '[%]',
			color: '#fcf003',
			unitSymbol: '%'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'ETH',
			type: 'line',
			seriesGroup: '[%]',
			color: '#036ffc',
			unitSymbol: '%'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'BCH',
			type: 'line',
			seriesGroup: '[%]',
			color: `#0ffc03`,
			unitSymbol: '%'
		},
		{
			data: series4,
			strokeWidth: 2,
			name: 'AVAX',
			type: 'line',
			seriesGroup: '[%]',
			color: '#fc1c03',
			unitSymbol: '%'
		},
		{
			data: series5,
			strokeWidth: 2,
			name: 'BNB',
			type: 'line',
			seriesGroup: '[%]',
			color: `${color}`,
			unitSymbol: '%'
		},
		{
			data: series6,
			strokeWidth: 2,
			name: 'DOGE',
			type: 'line',
			seriesGroup: '[%]',
			color: '#fc9d03',
			unitSymbol: '%'
		},
		{
			data: series7,
			strokeWidth: 2,
			name: 'LTC',
			type: 'line',
			seriesGroup: '[%]',
			color: '#03f4fc',
			unitSymbol: '%'
		},
		{
			data: series8,
			strokeWidth: 2,
			name: 'ATOM',
			type: 'line',
			seriesGroup: '[%]',
			color: '#8403fc',
			unitSymbol: '%'
		},
		{
			data: series10,
			strokeWidth: 2,
			name: 'STABLECOINS',
			type: 'line',
			seriesGroup: '[%]',
			color: '#184a12',
			unitSymbol: '%'
		}
	]
	return { config: config, latestValue: series9[series9.length - 1].y, latestValueDate: series9[series9.length - 1].x }
}

const SaverAPR = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			setDataSeries(await getSaverAPRConfig(color, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Savers APR"
				chartDesc="For every last block of each day, total saver yield is summed up from last 29 days. Based on the yield and current (as of the last day block timestamp) savers depth the APR is calculated.
                 The Stablecoin category includes all available stablecoin Saver pools."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="779d5d78-0acd-47cb-89c2-a0efd07c068b"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SaverAPR
