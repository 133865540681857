import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ICollateralAmount } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getLendingCollateralAmountConfig(color: string, inverseColor: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_collateral_amount', isRefreshing, timeframe)) as ICollateralAmount[]
	const btcData = chartData.filter((row) => row.COLLATERAL_ASSET === 'BTC.BTC')
	const ethData = chartData.filter((row) => row.COLLATERAL_ASSET === 'ETH.ETH')

	const series1 = btcData.map((element: ICollateralAmount) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.COLLATERAL_AMOUNT_USD }
	})
	const series2 = ethData.map((element: ICollateralAmount) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.COLLATERAL_AMOUNT_USD }
	})

	const series4 = []
	for (let i = 0; i < chartData.length; i += 2) {
		series4.push({ x: new Date(chartData[i].DAY).getTime() / 1000, y: chartData[i].CUMULATIVE_BY_ASSET_COLLATERAL_AMOUNT_USD + chartData[i + 1].CUMULATIVE_BY_ASSET_COLLATERAL_AMOUNT_USD })
	}
	const config: IChart[] = [
		{
			data: series4,
			strokeWidth: 2,
			name: 'Total',
			type: 'line',
			seriesGroup: ' [usd]',
			color: `${color}`,
			unitSymbol: '$'
		},
		{
			data: series1,
			strokeWidth: 2,
			name: 'BTC',
			type: 'bars',
			seriesGroup: '[usd]',
			color: '#fc9d03',
			unitSymbol: '$'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'ETH',
			type: 'bars',
			seriesGroup: '[usd]',
			color: '#036ffc',
			unitSymbol: '$'
		}
	]
	return { config: config, latestValue: series4[series4.length - 1].y, latestValueDate: series4[series4.length - 1].x }
}

const LendingCollateralAmount = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			setDataSeries(await getLendingCollateralAmountConfig(color, inverseColor, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseMainColor = theme === 'dark' ? 'black' : 'white'
		InitializeData(mainColor, inverseMainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Collateral Amount"
				chartDesc="Amount put up as collateral to open a loan on THORChain."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="cdd1b39d-694e-4b18-967a-51394501b6f3"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default LendingCollateralAmount
