import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IExchangeBalance } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const CexBalances = () => {
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_exchange_balance', true, timeframe)) as IExchangeBalance[]
			const series1 = chartData.map((element: IExchangeBalance) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.BINANCE_BALANCE }
			})
			const series2 = chartData.map((element: IExchangeBalance) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CRYPTOCOM_BALANCE }
			})
			const series3 = chartData.map((element: IExchangeBalance) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.KUCOIN_BALANCE }
			})
			const series4 = chartData.map((element: IExchangeBalance) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.KRAKEN_BALANCE }
			})
			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'Binance balance',
					type: 'bars',
					seriesGroup: '[rune]',
					color: 'green',
					unitSymbol: 'ᚱ',
					barGroup: 'balance'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'Crypto.com balance',
					type: 'bars',
					seriesGroup: '[rune]',
					color: 'orange',
					unitSymbol: 'ᚱ',
					barGroup: 'balance'
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'kuCoin balance',
					type: 'bars',
					seriesGroup: '[rune]',
					color: 'red',
					unitSymbol: 'ᚱ',
					barGroup: 'balance'
				},
				{
					data: series4,
					strokeWidth: 2,
					name: 'Kraken balance',
					type: 'bars',
					seriesGroup: '[rune]',
					color: 'purple',
					unitSymbol: 'ᚱ',
					barGroup: 'balance'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="CEX Balances"
				chartDesc="Centralized exchange rune balances.
				 Crypto.com addresses: thor1ty6h2ll07fqfzumphp6kq3hm4ps28xlm2l6kd6.
				  Binance addresses: thor1cqg8pyxnq03d88cl3xfn5wzjkguw5kh9enwte4, thor1t60f02r8jvzjrhtnjgfj4ne6rs5wjnejwmj7fh, thor1uz4fpyd5f5d6p9pzk8lxyj4qxnwq6f9utg0e7k.
				  KuCoin addresses: thor1jw0nhlmj4lv83dwhfknqnw6tmlvgw4xyf6rgd7, thor1hy2ka6xmqjfcwagtplyttayug4eqpqhu0sdu6r, thor1qdhev4z0fdrkec49qusutra4v4dvr53r2t79rp.
				  Kraken addresses: thor1nm0rrq86ucezaf8uj35pq9fpwr5r82clphp95t."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="80fc0008-8615-4117-8a8a-4d965d322376"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				reverseLegend={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default CexBalances
