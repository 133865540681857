import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ITCEarningsDistribution } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getPctOfEarningsFromLiqFeesConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_rune_earnings_distribution', isRefreshing, timeframe)) as ITCEarningsDistribution[]
	const series1 = chartData.map((element: ITCEarningsDistribution) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_OF_EARNINGS_FROM_LIQ_FEES }
	})
	const series2 = chartData.map((element: ITCEarningsDistribution) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_30D_MOVING_AVERAGE }
	})
	let config: IChart[]
	if (timeframe === '1d') {
		config = [
			{
				data: series1,
				strokeWidth: 2,
				name: 'liq fee pct',
				type: 'line',
				seriesGroup: '[%]',
				color: 'orange',
				unitSymbol: '%'
			},
			{
				data: series2,
				strokeWidth: 2,
				name: '30d moving avg',
				type: 'line',
				seriesGroup: '[%]',
				color: 'green',
				unitSymbol: '%'
			}
		]
	} else {
		config = [
			{
				data: series1,
				strokeWidth: 2,
				name: 'liq fee pct',
				type: 'line',
				seriesGroup: '[%]',
				color: 'orange',
				unitSymbol: '%'
			}
		]
	}

	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}
const PctOfEarningsFromLiqFees = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			setDataSeries(await getPctOfEarningsFromLiqFeesConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Percentage Of Earnings From Liq Fees"
				chartDesc="This chart shows the percentage of (LP and Node) earnings generated by liquidity (swap) fees."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="9bda51b5-c3ad-4ab6-abd8-17a004ace38d"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default PctOfEarningsFromLiqFees
