import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IAffiliateFees } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const AffiliateFees = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_aff_fees', true, timeframe)) as IAffiliateFees[]
			const thorswapData = chartData.filter((row) => row.LABEL === 'THORSwap')
			const thorwalletData = chartData.filter((row) => row.LABEL === 'THORWallet')
			const othersData = chartData.filter((row) => row.LABEL === 'Others')
			const xdefiData = chartData.filter((row) => row.LABEL === 'XDEFI')
			const rangoExData = chartData.filter((row) => row.LABEL === 'RangoExchange')
			const shapeShiftData = chartData.filter((row) => row.LABEL === 'ShapeShift')
			const asgardexData = chartData.filter((row) => row.LABEL === 'Asgardex')
			const trustwalletData = chartData.filter((row) => row.LABEL === 'TrustWallet')

			const series1 = thorswapData.map((element: IAffiliateFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.FEE_USD }
			})
			const series2 = thorwalletData.map((element: IAffiliateFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.FEE_USD }
			})
			const series3 = othersData.map((element: IAffiliateFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.FEE_USD }
			})
			const series4 = xdefiData.map((element: IAffiliateFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.FEE_USD }
			})
			const series5 = rangoExData.map((element: IAffiliateFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.FEE_USD }
			})
			const series6 = shapeShiftData.map((element: IAffiliateFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.FEE_USD }
			})
			const series7 = asgardexData.map((element: IAffiliateFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.FEE_USD }
			})
			const series9 = thorswapData.map((element: IAffiliateFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.TOTAL_CUMULATIVE_FEE_USD }
			})
			const series10 = trustwalletData.map((element: IAffiliateFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.FEE_USD }
			})
			setDataSeries([
				{
					data: series9,
					strokeWidth: 2,
					name: 'Cumulative Total',
					type: 'line',
					seriesGroup: ' [usd]',
					color: `orange`,
					unitSymbol: '$'
				},
				{
					data: series1,
					strokeWidth: 2,
					name: 'THORSwap',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `#0ffc03`,
					unitSymbol: '$',
					barGroup: 'positive'
				},
				{
					data: series10,
					strokeWidth: 2,
					name: 'TrustWallet',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `green`,
					unitSymbol: '$',
					barGroup: 'positive'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'THORWallet',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${color}`,
					unitSymbol: '$',
					barGroup: 'positive'
				},
				{
					data: series4,
					strokeWidth: 2,
					name: 'XDEFI',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `purple`,
					unitSymbol: '$',
					barGroup: 'positive'
				},
				{
					data: series5,
					strokeWidth: 2,
					name: 'RangoEx',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `#f542e9`,
					unitSymbol: '$',
					barGroup: 'positive'
				},
				{
					data: series6,
					strokeWidth: 2,
					name: 'ShapeShift',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `red`,
					unitSymbol: '$',
					barGroup: 'positive'
				},
				{
					data: series7,
					strokeWidth: 2,
					name: 'Asgardex',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `#07ebdf`,
					unitSymbol: '$',
					barGroup: 'positive'
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'Others',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `blue`,
					unitSymbol: '$',
					barGroup: 'positive'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Affiliate Fees"
				chartDesc="Affiliate fees can be taken for either swaps or liquidity adds (this chart only shows affiliate fees from swaps). The fee is set in basis points from 0 to 10000 (10%). (Query by @banbannard)."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="36b3b6b9-9e9c-4eef-a42f-a31fe7af0775"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default AffiliateFees
