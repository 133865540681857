import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IBorrowAmount } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getLendingBorrowAmountConfig(color: string, inverseColor: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_borrowed_amount', isRefreshing, timeframe)) as IBorrowAmount[]
	const btcData = chartData.filter((row) => row.BORROW_ASSET_LABEL === 'BTC.BTC')
	const ethData = chartData.filter((row) => row.BORROW_ASSET_LABEL === 'ETH.ETH')
	const runeData = chartData.filter((row) => row.BORROW_ASSET_LABEL === 'THOR.RUNE')
	const stablecoinData = chartData.filter((row) => row.BORROW_ASSET_LABEL === 'Stablecoin')
	const otherData = chartData.filter((row) => row.BORROW_ASSET_LABEL === 'Other')

	const series1 = btcData.map((element: IBorrowAmount) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.BORROW_AMOUNT_USD }
	})
	const series2 = ethData.map((element: IBorrowAmount) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.BORROW_AMOUNT_USD }
	})
	const series3 = runeData.map((element: IBorrowAmount) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.BORROW_AMOUNT_USD }
	})
	const series4 = stablecoinData.map((element: IBorrowAmount) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.BORROW_AMOUNT_USD }
	})
	const series5 = otherData.map((element: IBorrowAmount) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.BORROW_AMOUNT_USD }
	})

	const series6 = btcData.map((element: IBorrowAmount) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_BORROW_AMOUNT_USD }
	})
	const config: IChart[] = [
		{
			data: series6,
			strokeWidth: 2,
			name: 'Total',
			type: 'line',
			seriesGroup: ' [usd]',
			color: `${color}`,
			unitSymbol: '$'
		},
		{
			data: series1,
			strokeWidth: 2,
			name: 'BTC',
			type: 'bars',
			seriesGroup: '[usd]',
			color: '#fc9d03',
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'ETH',
			type: 'bars',
			seriesGroup: '[usd]',
			color: '#036ffc',
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'RUNE',
			type: 'bars',
			seriesGroup: '[usd]',
			color: 'green',
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series4,
			strokeWidth: 2,
			name: 'Stablecoin',
			type: 'bars',
			seriesGroup: '[usd]',
			color: '#03f4fc',
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series5,
			strokeWidth: 2,
			name: 'Other',
			type: 'bars',
			seriesGroup: '[usd]',
			color: '#8403fc',
			unitSymbol: '$',
			barGroup: 'usd'
		}
	]
	return { config: config, latestValue: series6[series6.length - 1].y, latestValueDate: series6[series6.length - 1].x }
}

const LendingBorrowAmount = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			setDataSeries(await getLendingBorrowAmountConfig(color, inverseColor, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseMainColor = theme === 'dark' ? 'black' : 'white'
		InitializeData(mainColor, inverseMainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Borrow Amount"
				chartDesc="Amount borrowed from THORChain protocol. (Query by @banbannard)"
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="981566a0-41df-4663-9733-e5d381d1a4f0"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				startZoom={Date.now() / 1000 - 3600 * 24 * 30 * 10}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default LendingBorrowAmount
