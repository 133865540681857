import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IBorrowerCount } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getLendingBorrowerCountConfig(color: string, inverseColor: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_borrower_count', isRefreshing, timeframe)) as IBorrowerCount[]
	let series1: {
		x: number
		y: number
	}[] = []

	if (timeframe === '1d') {
		series1 = chartData.map((element: IBorrowerCount) => {
			return { x: new Date(element.DAY).getTime() / 1000, y: element.DAILY_UNIQUE_BORROWER_COUNT }
		})
	} else if (timeframe === '1w') {
		series1 = chartData.map((element: IBorrowerCount) => {
			return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_UNIQUE_BORROWER_COUNT }
		})
	} else if (timeframe === '1m') {
		series1 = chartData.map((element: IBorrowerCount) => {
			return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_UNIQUE_BORROWER_COUNT }
		})
	}
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'Borrower Count',
			type: 'bars',
			seriesGroup: '#',
			color: 'green',
			unitSymbol: ''
		}
	]
	return { config: config, latestValue: series1[series1.length - 1].y, latestValueDate: series1[series1.length - 1].x }
}

const LendingBorrowerCount = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			setDataSeries(await getLendingBorrowerCountConfig(color, inverseColor, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseMainColor = theme === 'dark' ? 'black' : 'white'
		InitializeData(mainColor, inverseMainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Unique Borrower Count"
				chartDesc="Number of unique addresses which borrowed from THORChain."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="1d16aab4-199b-4890-b942-1c9487d43d35"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				startZoom={Date.now() / 1000 - 3600 * 24 * 30 * 10}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default LendingBorrowerCount
