import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ISaversYield } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getSaverYieldCumulativeConfig(color: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_savers_yield', isRefreshing, timeframe)) as ISaversYield[]
	const btcData = chartData.filter((row) => row.SAVER_POOL === 'BTC/BTC')
	const ethData = chartData.filter((row) => row.SAVER_POOL === 'ETH/ETH')
	const bchData = chartData.filter((row) => row.SAVER_POOL === 'BCH/BCH')
	const avaxData = chartData.filter((row) => row.SAVER_POOL === 'AVAX/AVAX')
	const bnbData = chartData.filter((row) => row.SAVER_POOL === 'BNB/BNB')
	const dogeData = chartData.filter((row) => row.SAVER_POOL === 'DOGE/DOGE')
	const ltcData = chartData.filter((row) => row.SAVER_POOL === 'LTC/LTC')
	const atomData = chartData.filter((row) => row.SAVER_POOL === 'GAIA/ATOM')
	const stablecoinData = chartData.filter((row) => row.SAVER_POOL === 'Stablecoin')

	const series1 = btcData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_YIELD_USD }
	})
	const series2 = ethData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_YIELD_USD }
	})
	const series3 = bchData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_YIELD_USD }
	})
	const series4 = avaxData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_YIELD_USD }
	})
	const series5 = bnbData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_YIELD_USD }
	})
	const series6 = dogeData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_YIELD_USD }
	})
	const series7 = ltcData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_YIELD_USD }
	})
	const series8 = atomData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_YIELD_USD }
	})
	const series10 = stablecoinData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_YIELD_USD }
	})
	const series9 = btcData.map((element: ISaversYield) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.TOTAL_CUMULATIVE_YIELD_USD }
	})
	const config: IChart[] = [
		{
			data: series9,
			strokeWidth: 2,
			name: 'TOTAL',
			type: 'line',
			seriesGroup: '[usd]',
			color: '#e505f5',
			unitSymbol: '$'
		},
		{
			data: series1,
			strokeWidth: 2,
			name: 'BTC',
			type: 'line',
			seriesGroup: '[usd]',
			color: `${color}`,
			unitSymbol: '$'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'ETH',
			type: 'line',
			seriesGroup: '[usd]',
			color: '#036ffc',
			unitSymbol: '$'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'BCH',
			type: 'line',
			seriesGroup: '[usd]',
			color: `#0ffc03`,
			unitSymbol: '$'
		},
		{
			data: series4,
			strokeWidth: 2,
			name: 'AVAX',
			type: 'line',
			seriesGroup: '[usd]',
			color: '#fc1c03',
			unitSymbol: '$'
		},
		{
			data: series5,
			strokeWidth: 2,
			name: 'BNB',
			type: 'line',
			seriesGroup: '[usd]',
			color: '#fcf003',
			unitSymbol: '$'
		},
		{
			data: series6,
			strokeWidth: 2,
			name: 'DOGE',
			type: 'line',
			seriesGroup: '[usd]',
			color: '#fc9d03',
			unitSymbol: '$'
		},
		{
			data: series7,
			strokeWidth: 2,
			name: 'LTC',
			type: 'line',
			seriesGroup: '[usd]',
			color: '#03f4fc',
			unitSymbol: '$'
		},
		{
			data: series8,
			strokeWidth: 2,
			name: 'ATOM',
			type: 'line',
			seriesGroup: '[usd]',
			color: '#8403fc',
			unitSymbol: '$'
		},
		{
			data: series10,
			strokeWidth: 2,
			name: 'STABLECOINS',
			type: 'line',
			seriesGroup: '[usd]',
			color: '#184a12',
			unitSymbol: '$'
		}
	]
	return { config: config, latestValue: series9[series9.length - 1].y, latestValueDate: series9[series9.length - 1].x }
}

const SaverYieldCumulative = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			setDataSeries(await getSaverYieldCumulativeConfig(color, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Savers Cumulative Yield"
				chartDesc="Cumulative yield for each of the Saver pools denominated in USD (BNB includes both BEP2 and BSC)."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="da4eb63b-373f-42ff-a9b9-c3a57b149d31"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SaverYieldCumulative
