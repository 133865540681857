export const contactUsLink = 'https://twitter.com/pietrekt_'
export const colors = [
	'#34eba8',
	'#3458eb',
	'#eb4034',
	'#eb6b34',
	'#9e34eb',
	'#eb9e34',
	'#969de0',
	'#92eb34',
	'#eb3467',
	'yellow',
	'#ebdddd',
	'#43eb34',
	'#eb34de',
	'#ebcf34',
	'#cfeb34',
	'#34eb71',
	'#34cfeb',
	'#4334eb',
	'#6e34eb',
	'#9e34eb',
	'#34ebbd',
	'#e0969b'
]
