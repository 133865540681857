import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IRunePoolDepositvsWithdraw } from '../../api/interfaces'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'
import { colors } from '../../const'

export async function getRunePoolProviderWithdrawDepositConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_runepool_deposit_vs_withdraw', isRefreshing, timeframe)) as IRunePoolDepositvsWithdraw[]
	console.log(chartData)
	const series1 = chartData.map((element: IRunePoolDepositvsWithdraw) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.DEPOSITED }
	})
	const series2 = chartData.map((element: IRunePoolDepositvsWithdraw) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.WITHDRAWN }
	})

	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'Deposited',
			type: 'bars',
			seriesGroup: '[ᚱune]',
			color: `green`,
			unitSymbol: 'ᚱ'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'Withdrawn',
			type: 'bars',
			seriesGroup: '[ᚱune]',
			color: `red`,
			unitSymbol: 'ᚱ'
		}
	]

	return { config: config, latestValue: 0, latestValueDate: 0 }
}

const RunePoolProviderWithdrawDeposit = () => {
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getRunePoolProviderWithdrawDepositConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="RUNEPool Provider Deposits"
				chartDesc="Provider deposits and withdrawals to the RUNEPool. RUNEPool is a feature of THORChain designed to optimise the utilisation of RUNE by depositing it into every POL-enabled liquidity pool in the network."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="8e75fbf4-8a5f-477d-9e39-b811d33c2834"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default RunePoolProviderWithdrawDeposit
