import React, { useEffect, useContext, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ThemeContext } from '../../App'
import { SwapVolumeTCVsExternal } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const TcVolumeDominance = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'volume_tc_vs_external', true, timeframe)) as SwapVolumeTCVsExternal[]
			const tcData = chartData.filter((row) => row.PROTOCOL_NAME === 'THORChain')
			let series1: {
				x: number
				y: number
			}[] = []
			if (timeframe === '1d') {
				series1 = tcData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.PCT_OF_TOTAL }
				})
			} else if (timeframe === '1w') {
				series1 = tcData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
			} else if (timeframe === '1m') {
				series1 = tcData.map((element: SwapVolumeTCVsExternal) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
			}

			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'Total Volume by TC',
					type: 'line',
					seriesGroup: '[% of total]',
					color: 'green',
					unitSymbol: '%'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseColor = theme === 'dark' ? 'black' : 'white'

		InitializeData(mainColor, inverseColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="THORChain Volume Dominance"
				chartDesc="Percentage of total DEX Volume done by THORChain. Data from Defillama."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="66b87c85-7b06-495b-baca-651d51578068"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasDays={true}
				reverseLegend={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default TcVolumeDominance
