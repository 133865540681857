import React, { useEffect, useState } from 'react'
import { getBackendData } from '../../api/api'
import { IModuleBalances } from '../../api/interfaces'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getTotalValueLockedConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_module_balances', isRefreshing, timeframe)) as IModuleBalances[]
	const series1 = chartData.map((element: IModuleBalances) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.POOL_MODULE_BALANCE * 2 + element.BOND_MODULE_BALANCE }
	})
	const series2 = chartData.map((element: IModuleBalances) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.POOL_MODULE_BALANCE_USD * 2 + element.BOND_MODULE_BALANCE_USD }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'tv locked [rune]',
			type: 'line',
			seriesGroup: '[rune]',
			color: 'green',
			unitSymbol: 'ᚱ'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'tv locked [usd]',
			type: 'line',
			seriesGroup: '[usd]',
			color: 'orange',
			unitSymbol: '$'
		}
	]
	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}

const TotalValueLocked = () => {
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getTotalValueLockedConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Total Value Locked"
				chartDesc="Total value locked is the sum of pooled and bonded assets in the THORChain network."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="b36f1290-e971-4dfc-84b7-792674bf63c4"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default TotalValueLocked
