import React from 'react'
import { Row } from 'react-bootstrap'
import ChartContent from '../../components/Chart/ChartContent'
import ChartDescription from '../../components/ChartDescription/ChartDescription'
import { IChart } from '../../components/Chart/interface'
interface IChartPageProps {
	chartTitle: string
	chartData: IChart[]
	chartDescTitle: string
	chartDesc: string
	dataTipTxt?: string
	queryId?: string
	timeframe?: string
	setTimeframe?: React.Dispatch<React.SetStateAction<string>>
	hasHours?: boolean
	hasDays?: boolean
	hasLogScale?: boolean
	reverseLegend?: boolean
	startZoom?: number
	endZoom?: number
}

const ChartPage: React.FunctionComponent<IChartPageProps> = (props) => {
	return (
		<>
			<Row>
				<ChartContent
					dataSeries={props.chartData}
					chartHeight="60vh"
					chartWidth="100%"
					chartTitle={props.chartTitle}
					timeframe={props.timeframe}
					setTimeframe={props.setTimeframe}
					hasHours={props.hasHours}
					hasDays={props.hasDays === undefined ? true : props.hasDays}
					hasLogScale={props.hasLogScale === undefined ? false : props.hasLogScale}
					reverseLegend={props.reverseLegend}
					startZoom={
						props.startZoom ? props.startZoom : props.timeframe === '1w' ? Date.now() / 1000 - 3600 * 24 * 30 * 12 : props.timeframe === '1m' ? undefined : Date.now() / 1000 - 3600 * 24 * 30 * 3
					}
					endZoom={props.endZoom}
				/>
				<ChartDescription title={props.chartDescTitle} text={props.chartDesc} queryId={props.queryId} />
			</Row>
		</>
	)
}

export default ChartPage
