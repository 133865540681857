export function nFormatter(num: number, prefix = '') {
	if (prefix === '%') {
		return num.toFixed(1) + prefix
	}
	let digits = 2
	if (Math.abs(num) < 10) {
		digits = 3
	}
	if (Math.abs(num) < 1) {
		digits = 3
	}
	let isNegativeNum = false
	if (num < 0) {
		isNegativeNum = true
	}
	num = Math.abs(num)
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' }
	]
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
	const item = lookup
		.slice()
		.reverse()
		.find(function (item) {
			return num >= item.value
		})

	if (num === 0) {
		return prefix + '0'
	}

	if (isNegativeNum) {
		if (prefix !== '+') {
			return item ? prefix + '-' + (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : prefix + '-' + num.toFixed(digits)
		}
		return item ? '-' + (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '-' + num.toFixed(digits)
	} else {
		return item ? prefix + (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : prefix + num.toFixed(digits)
	}
}
function padTo2Digits(num: number) {
	return num.toString().padStart(2, '0')
}
export function dateFormatter(dateTimestamp: number) {
	const date = new Date(dateTimestamp * 1000)
	return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-')
	// +
	// ' ' +
	// [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
}
