import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IRuneBurnt } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getLendingRuneBurntConfig(color: string, inverseColor: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_rune_burnt', isRefreshing, timeframe)) as IRuneBurnt[]
	const series1 = chartData.map((element: IRuneBurnt) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.RUNE_BURNED }
	})
	const series2 = chartData.map((element: IRuneBurnt) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_NET_RUNE_BURNED }
	})
	const series3 = chartData.map((element: IRuneBurnt) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.RUNE_MINTED }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'Rune Burned',
			type: 'bars',
			seriesGroup: '[ᚱUNE]',
			color: 'green',
			unitSymbol: 'ᚱ'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'Rune Minted',
			type: 'bars',
			seriesGroup: '[ᚱUNE]',
			color: 'red',
			unitSymbol: 'ᚱ'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'NET Rune Burned',
			type: 'line',
			seriesGroup: ' [ᚱUNE]',
			color: 'orange',
			unitSymbol: 'ᚱ'
		}
	]
	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}

const LendingRuneBurnt = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			setDataSeries(await getLendingRuneBurntConfig(color, inverseColor, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseMainColor = theme === 'dark' ? 'black' : 'white'
		InitializeData(mainColor, inverseMainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="ᚱUNE Mint/Burn"
				chartDesc="Amount of RUNE burned/minted in the process of opening loans on THORChain. (Query by @banbannard)"
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="d9a3ea55-6ee3-4017-8e64-987c52a76df9"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				reverseLegend={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default LendingRuneBurnt
